em-emoji-picker {
  --font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Raleway", sans-serif;
  --shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.19);
  --font-size: 12px;
  --category-icon-size: 18px;

  /* цвет фона */
  --rgb-background: 241, 241, 241;
  --border-radius: 16px;
  /* цвет шрифта */
  --rgb-color: 34, 36, 39;
  /* цвет выделения под секцией */
  --rgb-accent: 165, 165, 165;

  height: 250px;
}

.sticky {
  font-weight: 300 !important;
}

em-emoji-picker:host {
  font-weight: 300;
}

/* стиль для треугольника */
.emoji-mart:before {
  display: block;
  position: absolute;
  content: "";
  top: -9px;
  right: 24px;
  transform: translate(-50%, 0) rotate(45deg);
  width: 15px;
  height: 15px;
  background: #f1f1f1;
  border-left: 1px solid #e2e2e2;
  border-top: 1px solid #e2e2e2;
  border-radius: 3px;
  box-shadow: -4px -4px 7px -4px rgba(0, 0, 0, 0.1);
}
