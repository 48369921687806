@import "assets/fonts/fonts.css";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

p,
h1,
h2 {
  margin: 0;
}

/*
  React Table
*/

.ReactTable {
  border: none;
}

.ReactTable .rt-table {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
}

.rt-resizable-header-content {
  color: rgba(0, 0, 0, 0.2);
  font-size: 12px;
}

.ReactTable .rt-tbody .rt-tr-group {
  border: none;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  border: none;
  padding: 15px 5px;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 15px 5px;
}

.ReactTable .-pagination {
  border-radius: 16px;
}

.ReactTable .-pagination .-pageInfo {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.ReactTable .rt-tbody .rt-td {
  border: none;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

/*
  React Paginate
*/

ul.pagination {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  font-weight: 300;
  font-size: 18px;
}

.pagination > li > a {
  display: block;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 300ms ease;
  text-align: center;
}

.pagination > li > a:hover {
  color: black;
  background-color: white;
  border: 2px solid #8b5ff0;
}

.pagination > li.active > a {
  color: white;
  background-color: #8b5ff0;
}

.pagination li.disabled {
  opacity: 0.4;
}

.pagination a {
  text-decoration: none;
  outline: none;
}

/*
  React Modal
*/
.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.15);
}

/* по-дефолту модалка 900px */
.ReactModal__Content {
  width: 900px;
}

/* но модалка на Товарах и сотрудниках меньше */
.ReactModal__Content.create-item-modal {
  width: 600px;
}

/* но модалка на диалогах меньше */
.ReactModal__Content.create-dialogue-modal {
  width: 700px;
}

/* ужмем окна модалок на планшетах и мобиле */
@media all and (max-width: 960px) {
  .ReactModal__Content,
  .ReactModal__Content.create-dialogue-modal,
  .ReactModal__Content.create-item-modal {
    width: 80%;
  }
}

.ReactModal__Content::-webkit-scrollbar {
  width: 10px;
}

.ReactModal__Content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0 16px 16px 0;
}

.ReactModal__Content::-webkit-scrollbar-thumb {
  background: rgb(173, 173, 173);
  border-radius: 0 16px 16px 0;
}

.ReactModal__Content::-webkit-scrollbar-thumb:hover {
  background: rgb(150, 150, 150);
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

/*
  intro.js
*/
.introjs-tooltip {
  padding: 10px;
}

.introjs-tooltipReferenceLayer {
  /*force GPU rendering:
  https://stackoverflow.com/a/73418817/5371197
  ChatGPT answer:
  The transform: translateZ(0); forces the browser to create a new layer for the element,
  which can resolve rendering issues in Safari.*/
  transform: translateZ(0);
}

.introjs-bottom-left-aligned {
  margin-left: 20px;
}

.introjs-tooltip .introjs-tooltip-header {
  padding: 0;
}

.introjs-tooltip-header .introjs-skipbutton {
  padding: 0;
}

.introjs-tooltip .introjs-tooltiptext {
  width: 300px;
  font-family: Raleway, sans-serif;
  font-feature-settings: "lnum";
  font-weight: 300;
  padding: 0;
}

.introjs-progress .introjs-progressbar {
  background-color: #8b5ff0;
}

.introjs-tooltip .introjs-tooltipbuttons {
  border: none;
  padding: 0;
}

.introjs-tooltipbuttons .introjs-button {
  border: 1px solid #8b5ff0;
  font-family: Raleway, sans-serif;
  font-feature-settings: "lnum";
  background-color: white;
  font-size: 14px;
  color: #8b5ff0;
  border-radius: 4px;
  outline: none;
  padding: 5px;
  margin: 0;
  text-shadow: none;
  background-image: none;
  transition: 300ms ease;
  cursor: pointer;
}

.introjs-tooltipbuttons .introjs-button:hover {
  background-color: #8b5ff0;
  color: white;
}

.introjs-tooltipbuttons .introjs-button:focus {
  background-image: none;
  border-color: #8b5ff0;
}

.introjs-helperLayer {
  box-shadow:
    rgba(33, 33, 33, 0.8) 0px 0px 5px 0px,
    rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}

/*
  React Datepicker
*/
.react-datepicker.datepicker__calendar {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.192708);
  border: none;
  background-color: transparent;
  overflow: hidden;
  font-size: 16px;
  font-family: Raleway, sans-serif;
  font-feature-settings: "lnum";
  margin-bottom: 10px;
  display: flex;
  border-radius: 12px;
  max-width: 400px;
}

.datepicker__calendar .react-datepicker__time-container {
  border-left: 1px #8b5ff0 solid;
  width: 100%;
}

.react-datepicker.datepicker__calendar .react-datepicker__time-box {
  width: 100%;
  margin: 0;
}

.datepicker__calendar .react-datepicker__time {
  background-color: transparent;
}

.datepicker__calendar .react-datepicker__header {
  border-bottom: 1px #8b5ff0 solid;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* дни календаря */
.datepicker__calendar .react-datepicker__day {
  border-radius: 100%;
  border: 1px transparent solid;
  width: 32px;
  height: 32px;
  line-height: 32px;
  transition: 0.3s ease;
  text-align: center;
  outline: unset;
}

@media all and (max-width: 960px) {
  .datepicker__calendar .react-datepicker__day {
    width: 26px;
    height: 26px;
    line-height: 26px;
  }
}

.datepicker__calendar .react-datepicker__day:hover {
  border: 1px #c9b0fb solid;
  background-color: #c9b0fb;
  color: white;
}

.datepicker__calendar .react-datepicker__current-month {
  text-transform: capitalize;
}

/* временные ячейки */
.datepicker__calendar .react-datepicker__time-list-item {
  transition: 0.3s ease;
}

.datepicker__calendar
  .react-datepicker__time-container
  > .react-datepicker__time
  > .react-datepicker__time-box
  > .react-datepicker__time-list
  > .react-datepicker__time-list-item:hover {
  background-color: #c9b0fb;
  color: white;
}

.datepicker__calendar .react-datepicker__day--selected,
.datepicker__calendar .react-datepicker__time-list-item--selected,
.datepicker__calendar .react-datepicker__day--keyboard-selected {
  background-color: #8b5ff0 !important;
}

.datepicker__calendar .react-datepicker__navigation--next,
.datepicker__calendar .react-datepicker__navigation--previous {
  border: none;
  width: 10px;
  height: 19px;
  background-size: contain;
}

.datepicker__calendar .react-datepicker__navigation--next:focus,
.datepicker__calendar .react-datepicker__navigation--previous:focus {
  outline: none;
}

.datepicker__calendar .react-datepicker__navigation--previous {
  background: url("./assets/arrow-left-datepicker.svg") no-repeat;
}

.datepicker__calendar .react-datepicker__navigation--next {
  background: url("./assets/arrow-right-datepicker.svg") no-repeat;
}

.datepicker__calendar .react-datepicker__navigation--next:focus {
  /*border: none;*/
}

.datepicker__calendar .react-datepicker__week {
  display: flex;
  flex-wrap: wrap;
}

.datepicker__calendar .react-datepicker__day-names,
.react-datepicker__week {
  white-space: unset;
}

.datepicker__calendar .react-datepicker__month-container {
  width: 100%;
  flex-basis: 70%;
  flex-grow: 7;
}

.datepicker__calendar .react-datepicker__time-container {
  flex-basis: 30%;
  flex-grow: 3;
}

.datepicker__calendar .react-datepicker__month-container,
.datepicker__calendar .react-datepicker__time-container {
  float: none;
}

/*
  React Burger Menu
*/

/*  */
.palette-burger-menu .bm-burger-button {
  opacity: 1;

  &:hover {
    opacity: 0.5;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  outline: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.landing .bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  outline: none;
}

/* General sidebar styles */
.navigation-burger-menu .bm-menu {
  padding: 0;
}

.bm-menu {
  padding-top: 30px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.navigation-burger-menu .bm-item-list {
  justify-content: normal;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  outline: none;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.palette-burger-menu .bm-overlay {
  left: 0;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
